:root {
  --light-bg: #e4ebf5;
  --light-bg-dark-shadow: #bec8e4;
  --light-bg-light-shadow: #ffffff;

  --dark-bg: #444444;
  --dark-bg-dark-shadow: #363636;
  --dark-bg-light-shadow: #525252;

  --white: #ffffff;
  --black: #000000;

  --primary: #2979ff;
  --primary-dark: #2962ff;
  --primary-light: #82b1ff;

  --error: #ff5252;
  --info: #2196f3;
  --success: #4caf50;
  --warning: #dfa700;

  --g-text-color-light: rgba(0, 0, 0, 0.93);
  --g-text-color-disabled-light: rgba(0, 0, 0, 0.45);
  --g-text-color-secondary-light: rgba(0, 0, 0, 0.7);

  --g-text-color-dark: rgba(255, 255, 255, 0.93);
  --g-text-color-disabled-dark: rgba(255, 255, 255, 0.45);
  --g-text-color-secondary-dark: rgba(255, 255, 255, 0.7);

  --g-bg-color-disabled-light: #dee5e8;
  --g-bg-color-disabled-dark: #727272;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

input,
label,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent !important;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

a {
  text-decoration: none;
  color: var(--primary);
}

a:hover {
  text-decoration: underline;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._17_OS {
  cursor: pointer;
}

._jzWXa {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px !important;
  padding: 4px !important;
}

._1TF7v {
  width: 100px;
  height: 24px;
}

._30opT {
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
  color: var(--avatar-text-color);
  background: var(--avatar-bg-color);
  --avatar-bg-color: var(--primary);
  --avatar-text-color: var(--white);
}

._gxOv4 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  text-align: center;
}

._DlS5H {
  width: 24px;
  height: 24px;
}

._HQ-dl {
  width: 40px;
  height: 40px;
}

._102pn {
  width: 56px;
  height: 56px;
}

._1Rtx1 {
  border-radius: 50%;
}

._3i8AP {
  border-radius: 0px;
}

._1jhwD {
  border-radius: 8px;
}

._cL4RU {
  outline: none;
  min-width: 64px;
  padding: 0 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color);
  height: var(--btn-height);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-color);
  transition: box-shadow 200ms ease-in-out;
  -webkit-tap-highlight-color: transparent !important;
}

._cL4RU._CWiZp {
  --bg-color: var(--light-bg);
  --border-color: var(--text-color);
  --text-color: var(--g-text-color-light);
  --light-border-color: var(--light-bg-dark-shadow);
  --text-color-disabled: var(--g-text-color-disabled-light);
  --box-shadow: 5px 5px 12px var(--light-bg-dark-shadow),
    -4px -4px 10px var(--light-bg-light-shadow);
  --box-shadow-hover: 2px 2px 5px var(--light-bg-dark-shadow),
    -2px -2px 5px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--light-bg-light-shadow),
    inset 2px 2px 5px var(--light-bg-dark-shadow);
  --box-shadow-inset-low: inset -1px -1px 3px var(--light-bg-light-shadow),
    inset 1px 1px 3px var(--light-bg-dark-shadow);
  --box-shadow-fab: 10px 10px 20px var(--light-bg-dark-shadow),
    -7px -7px 16px var(--light-bg-light-shadow);
}

._cL4RU._3tLvr {
  --bg-color: var(--dark-bg);
  --border-color: var(--text-color);
  --text-color: var(--g-text-color-dark);
  --light-border-color: var(--dark-bg-light-shadow);
  --text-color-disabled: var(--g-text-color-disabled-dark);
  --box-shadow: 5px 5px 12px var(--dark-bg-dark-shadow),
    -4px -4px 10px var(--dark-bg-light-shadow);
  --box-shadow-hover: 2px 2px 5px var(--dark-bg-dark-shadow),
    -2px -2px 5px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--dark-bg-light-shadow),
    inset 2px 2px 5px var(--dark-bg-dark-shadow);
  --box-shadow-inset-low: inset -1px -1px 3px var(--dark-bg-light-shadow),
    inset 1px 1px 3px var(--dark-bg-dark-shadow);
  --box-shadow-fab: 10px 10px 20px var(--dark-bg-dark-shadow),
    -7px -7px 16px var(--dark-bg-light-shadow);
}

._cL4RU._1YdKB,
._cL4RU._2vvr7,
._cL4RU._3hFGr {
  transition: none;
}

._cL4RU._2vvr7,
._cL4RU._3hFGr {
  padding: 0;
}

._cL4RU._2vvr7 {
  width: var(--btn-height);
  max-width: var(--btn-height);
}

._cL4RU._1YdKB {
  height: var(--fab-size);
  min-width: var(--fab-size);
  min-height: var(--fab-size);
  box-shadow: var(--box-shadow-fab) !important;
}

._cL4RU._2vvr7,
._cL4RU._3hFGr {
  min-width: var(--btn-height);
  min-height: var(--btn-height);
  box-shadow: var(--box-shadow-hover) !important;
}

._1YdKB._37by5 {
  --fab-size: 40px;
}

._1YdKB._3W7Om {
  --fab-size: 48px;
}

._1YdKB._r7kxw {
  --fab-size: 56px;
}

._cL4RU._37by5 {
  --btn-height: 30px;
}

._cL4RU._3W7Om {
  --btn-height: 36px;
}

._cL4RU._r7kxw {
  --btn-height: 42px;
}

._cL4RU._2ULiP {
  display: flex;
}

._cL4RU._39_ND {
  border-radius: 56px !important;
}

._cL4RU._2YNuR {
  opacity: 0.6;
  pointer-events: none;
  --border-color: var(--text-color-disabled);
  --text-color: var(--text-color-disabled);
}

._cL4RU._1qWOD {
  box-shadow: none !important;
  transition: none !important;
  --bg-color: transparent !important;
}

._cL4RU._z9EYS {
  border: 1px solid var(--light-border-color);
}

._cL4RU._1crAB {
  box-shadow: none !important;
  transition: none !important;
  --bg-color: transparent !important;
  border: 1px solid var(--border-color);
}

._cL4RU._EotAP {
  box-shadow: var(--box-shadow-hover);
}

._cL4RU:hover {
  box-shadow: var(--box-shadow-hover);
}

._cL4RU:active,
._cL4RU._28gcN {
  transition: none !important;
}

._cL4RU:active,
._cL4RU._28gcN:active {
  box-shadow: var(--box-shadow-inset) !important;
}

._cL4RU._28gcN {
  box-shadow: var(--box-shadow-inset-low) !important;
}

._cL4RU._2W0Fs:active {
  box-shadow: none !important;
}

._cL4RU:focus {
  outline: none;
  padding: 0 10px;
  border: 2px solid var(--border-color);
}

._cL4RU._1crAB:focus {
  padding: 0 11px;
}

._3lLt8 {
  letter-spacing: 1.05px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
  border: none;
  outline: none;
  color: inherit;
  cursor: inherit;
  display: inherit;
  max-width: inherit;
  box-sizing: inherit;
  align-items: inherit;
  text-shadow: inherit;
  border-radius: inherit;
  justify-content: inherit;
  text-transform: uppercase;
  background-color: inherit;
  -webkit-tap-highlight-color: transparent !important;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

._4B6J_ {
  outline: none;
  padding: 0 10px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  text-decoration: none;
  vertical-align: middle;
  color: var(--text-color);
  transition-duration: 0.3s;
  background: var(--bg-color);
  box-shadow: var(--box-shadow);
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, opacity;
}

._4B6J_:hover {
  text-decoration: none !important;
}

._4B6J_._1Y3po {
  --bg-color: var(--light-bg);
  --text-color: var(--g-text-color-light);
  --border-color: var(--light-bg-dark-shadow);
  --box-shadow: -2px -2px 3px var(--light-bg-light-shadow),
    1px 1px 2px var(--light-bg-dark-shadow);
  --box-shadow-inset: inset -1px -1px 2px var(--light-bg-light-shadow),
    inset 2px 2px 3px var(--light-bg-dark-shadow);
}

._4B6J_._1Z5zg {
  --bg-color: var(--dark-bg);
  --text-color: var(--g-text-color-dark);
  --border-color: var(--dark-bg-dark-shadow);
  --box-shadow: 2px 2px 3px var(--dark-bg-dark-shadow),
    -1px -1px 2px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset 1px 1px 2px var(--dark-bg-dark-shadow),
    inset -2px -2px 3px var(--dark-bg-light-shadow);
}

._4B6J_._C19fA {
  box-shadow: var(--box-shadow-inset);
}

._4B6J_._XnRhC {
  border-radius: 8px !important;
}

._4B6J_._1LBtg {
  box-shadow: none !important;
}

._4B6J_._2va-3 {
  border: 1px solid var(--border-color);
}

._4B6J_._2KXIV {
  box-shadow: none !important;
  border: 1px solid var(--text-color);
}

._4B6J_._3XUk8 {
  height: 16px;
  font-size: 10px;
}

._4B6J_._1Pf7_ {
  height: 24px;
  font-size: 12px;
}

._4B6J_._2WYlD {
  height: 32px;
  font-size: 14px;
}

._4B6J_._MP_qE {
  --text-color: var(--info);
}

._4B6J_._1KsL0 {
  --text-color: var(--error);
}

._4B6J_._336jk {
  --text-color: var(--warning);
}

._4B6J_._3LwMx {
  --text-color: var(--success);
}

._4B6J_._1Pf7_ ._3nEad {
  margin-left: 4px;
  margin-right: -6px;
}

._4B6J_._1Pf7_ ._cDvCc {
  margin-left: -6px;
  margin-right: 4px;
}

._4B6J_ ._3YiUZ {
  margin-left: 4px;
  margin-right: -4px;
}

._4B6J_ ._3YiUZ,
._4B6J_ ._3YiUZ._2j5lw {
  cursor: pointer;
}

._4B6J_ ._3YiUZ._2j5lw {
  margin-left: 8px;
  margin-right: 0px;
}

._4B6J_._3XUk8 ._2j5lw,
._4B6J_._1Pf7_ ._2j5lw {
  transform: scale(1.5);
}

._4B6J_._2WYlD ._2j5lw {
  transform: scale(1.65);
}

._4B6J_ ._3nEad._bL2DT {
  margin-right: 0px;
}

._3RmuL {
  display: inline-flex;
}

._3RmuL._3XYq8 {
  transform-origin: center center;
  -webkit-animation: _2xXgg 350ms ease-in-out 1;
          animation: _2xXgg 350ms ease-in-out 1;
}

._2fQ45 {
  position: fixed;
}

._1yPi- {
  position: absolute;
}

._3RmuL._MXoMR {
  top: 16px;
}

._3RmuL._39m3p {
  right: 16px;
}

._3RmuL._1SKZA {
  bottom: 16px;
}

._3RmuL._300yT {
  left: 16px;
}

@-webkit-keyframes _2xXgg {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(0)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes _2xXgg {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(0)
  }

  100% {
    transform: scale(1)
  }
}

._ZcHct {
  height: 36px;
  overflow: hidden;
  position: relative;
}

._ZcHct ._2NgZv {
  top: 0;
  height: 36px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  transition: all 200ms ease-in-out;
  box-shadow: var(--box-shadow-inset);
}

._1G36q ._2NgZv {
  border-radius: 18px;
}

._3AEIw ._2NgZv {
  box-shadow: none !important;
  border: 1px solid var(--selector-bg);
}

._1km6c ._2NgZv {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  box-shadow: none !important;
  border-bottom: 2px solid var(--selector-bg);
}

._2Yk9X {
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-in-out;
}

._1KP4a {
  height: inherit;
  overflow: inherit;
  position: relative;
}

._pAlw7 {
  width: 100%;
  padding-top: 12px;
}

._1_tFB._1sEgp,
._ZcHct._1Q6IQ,
._2Yk9X._2GePn {
  --selector-bg: var(--primary);
  --box-shadow: 3px 3px 6px var(--light-bg-dark-shadow),
    -3px -3px 6px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--light-bg-light-shadow),
    inset 2px 2px 5px var(--light-bg-dark-shadow);
}

._1_tFB._GZEDh,
._ZcHct._15fiK,
._2Yk9X._27Dgb {
  --selector-bg: var(--primary);
  --box-shadow: 3px 3px 6px var(--dark-bg-dark-shadow),
    -3px -3px 6px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--dark-bg-light-shadow),
    inset 2px 2px 5px var(--dark-bg-dark-shadow);
}

._28vIk {
  width: auto;
  box-sizing: border-box;
}

._qCt7a {
  margin: 4px;
}

._1gHjR,
._2So3v,
._2TuLn {
  margin: 16px;
}

._28vIk,
._3KQfR,
._qCt7a,
._2TuLn,
._2So3v {
  font-size: 16px;
  border-radius: 8px;
  color: var(--text-color);
  background: var(--bg-color);
  background-color: var(--bg-color);
}

._2TuLn {
  font-size: 24px;
}

._qCt7a {
  display: flex;
  align-items: center;
}

._3KQfR {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px !important;
}

._28vIk._1SFBz,
._3KQfR._3XlrT,
._qCt7a._1l5Ig,
._2TuLn._2vg-Y,
._2So3v._2uw1B {
  --bg-color: var(--light-bg);
  --text-color: var(--g-text-color-light);
  --border-color: var(--light-bg-dark-shadow);
  --box-shadow: 3px 3px 6px var(--light-bg-dark-shadow),
    -3px -3px 6px var(--light-bg-light-shadow);
  --box-shadow-2: 6px 6px 12px var(--light-bg-dark-shadow),
    -6px -6px 12px var(--light-bg-light-shadow);
  --box-shadow-3: 9px 9px 18px var(--light-bg-dark-shadow),
    -9px -9px 18px var(--light-bg-light-shadow);
  --box-shadow-4: 12px 12px 24px var(--light-bg-dark-shadow),
    -12px -12px 24px var(--light-bg-light-shadow);
  --box-shadow-5: 15px 15px 30px var(--light-bg-dark-shadow),
    -15px -15px 30px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--light-bg-light-shadow),
    inset 2px 2px 5px var(--light-bg-dark-shadow);
}

._28vIk._2nMGC,
._3KQfR._9VJ2s,
._qCt7a._1e8rA,
._2TuLn._1Vkyx,
._2So3v._BClU8 {
  --bg-color: var(--dark-bg);
  --text-color: var(--g-text-color-dark);
  --border-color: var(--dark-bg-light-shadow);
  --box-shadow: 3px 3px 6px var(--dark-bg-dark-shadow),
    -3px -3px 6px var(--dark-bg-light-shadow);
  --box-shadow-2: 6px 6px 12px var(--dark-bg-dark-shadow),
    -6px -6px 12px var(--dark-bg-light-shadow);
  --box-shadow-3: 9px 9px 18px var(--dark-bg-dark-shadow),
    -9px -9px 18px var(--dark-bg-light-shadow);
  --box-shadow-4: 12px 12px 24px var(--dark-bg-dark-shadow),
    -12px -12px 24px var(--dark-bg-light-shadow);
  --box-shadow-5: 15px 15px 30px var(--dark-bg-dark-shadow),
    -15px -15px 30px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--dark-bg-light-shadow),
    inset 2px 2px 5px var(--dark-bg-dark-shadow);
}

._28vIk._1qJ6l,
._3KQfR._uCBEl,
._qCt7a._3MMHx,
._2TuLn._1ZP9f,
._2So3v._1xGVC {
  border-radius: 24px;
}

._28vIk._1ac5u {
  box-shadow: var(--box-shadow-5);
}

._28vIk._33saR {
  box-shadow: var(--box-shadow-4);
}

._28vIk._2Y5h3 {
  box-shadow: var(--box-shadow-3);
}

._28vIk._3hBYZ {
  box-shadow: var(--box-shadow-2);
}

._28vIk._2bGnb {
  box-shadow: var(--box-shadow);
}

._28vIk._2xWhy {
  box-shadow: none;
}

._28vIk._2BfgR,
._28vIk._PR9zj {
  box-shadow: none !important;
}
._28vIk._12WTq,
._28vIk._2BfgR {
  border: 1px solid var(--border-color);
}

._28vIk._2Xmdp {
  box-shadow: var(--box-shadow-inset);
}

._3bkaL {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._3obGh {
  justify-content: flex-start;
}

.__N77l {
  margin-right: 16px;
}

._1gHjR {
  position: absolute;
  bottom: 0px;
}

._3KQfR._2fyoZ {
  opacity: 0.38;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

._1e1Zo {
  padding: 8px;
  min-width: 100%;
}

._1fAxu {
  outline: none;
  min-width: 100%;
  min-height: 42px;
  overflow: hidden;
  padding: 12px 16px;
  border-radius: 4px;
  text-decoration: none;
  --select-color: var(--primary);
  transition: box-shadow 0.2s ease-in-out;
  --item-box-shadow: var(--box-shadow-inset);
}

._1fAxu._3qLrW {
  --bg-color: var(--light-bg);
  --text-color: var(--g-text-color-light);
  --border-color: var(--light-bg-dark-shadow);
  --secondary-text-color: var(--g-text-color-secondary-light);
  --box-shadow: -3px -3px 4px var(--light-bg-light-shadow),
    2px 2px 3px var(--light-bg-dark-shadow);
  --box-shadow-inset: inset -2px -2px 3px var(--light-bg-light-shadow),
    inset 2px 2px 3px var(--light-bg-dark-shadow);
}

._1fAxu._VAjCd {
  --bg-color: var(--dark-bg);
  --text-color: var(--g-text-color-dark);
  --border-color: var(--dark-bg-dark-shadow);
  --secondary-text-color: var(--g-text-color-secondary-dark);
  --box-shadow: 3px 3px 4px var(--dark-bg-dark-shadow),
    -2px -2px 3px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset 2px 2px 3px var(--dark-bg-dark-shadow),
    inset -2px -2px 3px var(--dark-bg-light-shadow);
}

._1fAxu._en55A {
  --item-box-shadow: var(--box-shadow);
}

._1fAxu._1U34y {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

._1fAxu._2M8O0 {
  padding: 8px 12px;
}

._1fAxu._HIndY {
  padding: 12px 24px;
  border-radius: 64px;
}

._1fAxu._G0QTg {
  pointer-events: none !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

._1fAxu._3j39Z._HIndY {
  padding-left: 30px;
  padding-right: 32px;
  border-radius: 82px;
}

._1fAxu._2M8O0 ._3O9iB,
._1fAxu._2M8O0 ._14Sbl {
  font-size: 13px;
  font-weight: 500;
}

._1fAxu._2n-jE,
._1fAxu._1U34y:hover {
  box-shadow: var(--item-box-shadow);
}

._1fAxu._1U34y._1fAxu._3Q1QT:hover {
  box-shadow: none !important;
}

._1fAxu._2n-jE ._3O9iB {
  color: var(--select-color);
}

._3O9iB,
._14Sbl {
  flex: 1 1 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._3O9iB {
  font-size: 16px;
  color: var(--text-color);
}

._14Sbl {
  font-size: 14px;
  margin-top: 2px;
  color: var(--secondary-text-color);
}

._1fAxu._3j39Z ._14Sbl {
  margin-top: 8px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

._2RF0y {
  letter-spacing: -1.5px;
  font-weight: 300;
  font-size: 96px;
}

._2b-UI {
  letter-spacing: -0.5px;
  font-weight: 300;
  font-size: 60px;
}

._rTEEr {
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 48px;
}

._1N96j {
  letter-spacing: 0.25px;
  font-weight: 400;
  font-size: 34px;
}

._2TwOi {
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 24px;
}

._2wZct {
  letter-spacing: 0.15px;
  font-weight: 500;
  font-size: 20px;
}

._2BgaH {
  letter-spacing: 0.15px;
  font-weight: 400;
  font-size: 16px;
}

._2vNlX {
  letter-spacing: 0.1px;
  font-weight: 500;
  font-size: 14px;
}

._NwSHs {
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 16px;
}

._i_J81 {
  letter-spacing: 0.26px;
  font-weight: 400;
  font-size: 14px;
}

._2HbLl {
  letter-spacing: 0.4px;
  font-weight: 400;
  font-size: 12px;
}

._1yuzT {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
  font-size: 10px;
}

._2RF0y,
._2b-UI,
._rTEEr,
._1N96j,
._2TwOi,
._2wZct,
._NwSHs,
._i_J81,
._2HbLl,
._1yuzT,
._2BgaH,
._2vNlX {
  color: var(--text-color);
  -webkit-tap-highlight-color: transparent !important;
}

._3yPwv._11JjB._1uvOH {
  --text-color: var(--g-text-color-light);
}

._3yPwv._11JjB._3PUqy {
  --text-color: var(--g-text-color-dark);
}

._3yPwv._1QRSN._1uvOH {
  --text-color: var(--g-text-color-secondary-light);
}

._3yPwv._1QRSN._3PUqy {
  --text-color: var(--g-text-color-secondary-dark);
}

._3yPwv._31wpR {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

._3yPwv._31wpR._1uvOH {
  --text-color: var(--g-text-color-disabled-light);
}

._3yPwv._31wpR._3PUqy {
  --text-color: var(--g-text-color-disabled-dark);
}

._2M5iY {
  display: flex;
  width: inherit;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: var(--bg-color);
  transition: all 0.2s ease-in-out;
  box-shadow: var(--box-shadow-inset);
}

._2M5iY._1FvLK {
  border: 1px solid var(--border-color);
}

._2M5iY._20rTC,
.__234s._2YVD0 {
  --bg-color: var(--light-bg);
  --border-color: var(--light-bg-dark-shadow);
  --selector-bg: var(--g-text-color-secondary-light);
  --box-shadow-inset-dense: inset -1px -1px 3px var(--light-bg-light-shadow),
    inset 1px 1px 3px var(--light-bg-dark-shadow);
  --box-shadow-dense: -1px -1px 3px var(--light-bg-light-shadow),
    1px 1px 3px var(--light-bg-dark-shadow);
}

._2M5iY._2x6H_,
.__234s._2clvw {
  --bg-color: var(--dark-bg);
  --border-color: var(--dark-bg-light-shadow);
  --selector-bg: var(--g-text-color-secondary-dark);
  --box-shadow-inset-dense: inset -1px -1px 3px var(--dark-bg-light-shadow),
    inset 1px 1px 3px var(--dark-bg-dark-shadow);
  --box-shadow-dense: -1px -1px 3px var(--dark-bg-light-shadow),
    1px 1px 3px var(--dark-bg-dark-shadow);
}

._1wWaA {
  width: calc(100% - 4px);
  overflow: hidden;
}

._1wWaA._1PN0w {
  width: 100%;
}

._29wbC {
  width: 100%;
  overflow: hidden;
  background: var(--selector-bg);
  transition: all 0.2s ease-in-out;
}

._1aHNB {
  background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.4) 25%,
      transparent 0,
      transparent 50%,
      rgba(255, 255, 255, 0.4) 0,
      rgba(255, 255, 255, 0.4) 75%,
      transparent 0,
      transparent
    )
    var(--selector-bg) !important;
}

._2wIvQ {
  -webkit-animation: _1Q5K8 1.25s ease infinite;
          animation: _1Q5K8 1.25s ease infinite;
}

@-webkit-keyframes _1Q5K8 {
  0% {
    transform: translateX(-200%);
  }

  100% {
    width: 100%;
    transform: translateX(100%);
  }
}

@keyframes _1Q5K8 {
  0% {
    transform: translateX(-200%);
  }

  100% {
    width: 100%;
    transform: translateX(100%);
  }
}

/* circular */
.__234s {
  position: relative;
}

._1iTMT,
._3Jx6J,
._2hjq3,
._1QMv- {
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: 100%;
  background: var(--bg-color);
  transform: translate3d(-50%, -50%, 0);
}

._1iTMT {
  z-index: 2;
}

._3Jx6J {
  z-index: 1;
}

._NijRA ._1iTMT {
  box-shadow: var(--box-shadow-dense);
}

._NijRA ._3Jx6J {
  box-shadow: var(--box-shadow-inset-dense);
}

._2Ymai ._1iTMT {
  box-shadow: var(--box-shadow-inset-dense);
}

._2Ymai ._3Jx6J {
  box-shadow: var(--box-shadow-dense);
}

._3OXzH ._3Jx6J,
._3OXzH ._1iTMT {
  box-shadow: none !important;
}

._1QMv- {
  z-index: 2;
}

._2hjq3 {
  z-index: 3;
  color: var(--selector-bg);
}

._53Md9 {
  z-index: 3;
  fill: transparent;
  stroke: var(--selector-bg);
  transition: all 0.3s ease-in-out;
}

._2vn3- {
  -webkit-animation: _1cNbz 2s linear infinite;
          animation: _1cNbz 2s linear infinite;
}

._2vn3- circle {
  stroke-linecap: round;
  transform-origin: bottom right;
  -webkit-animation: _31C0g 1.4s ease-in-out infinite both;
          animation: _31C0g 1.4s ease-in-out infinite both;
}

@-webkit-keyframes _1cNbz {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes _1cNbz {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes _31C0g {
  0% {
    stroke-dashoffset: none;
    transform: rotate(0);
  }

  50% {
    stroke-dashoffset: initial;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: none;
    transform: rotate(360deg);
  }
}

@keyframes _31C0g {
  0% {
    stroke-dashoffset: none;
    transform: rotate(0);
  }

  50% {
    stroke-dashoffset: initial;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: none;
    transform: rotate(360deg);
  }
}

._3vnt0 {
  width: 100%;
  overflow: hidden;
  position: relative;
}

._3vnt0._1FIJN {
  --text-color: var(--g-text-color-light);
}

._3vnt0._3vJ-u {
  --text-color: var(--g-text-color-dark);
}

._PpWcw {
  height: inherit;
  overflow: inherit;
  position: inherit;
}

._3JvVb {
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 48px;
  display: flex;
  margin: 0px auto;
  position: absolute;
  align-items: center;
  justify-content: center;
}

._3waoy {
  margin: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 250ms ease-in-out;
  border: 2px solid var(--text-color);
}

._3waoy:after {
  top: 50%;
  width: 0%;
  height: 0%;
  content: '';
  margin: auto;
  display: block;
  border-radius: 50%;
  position: relative;
  transform: translateY(-50%);
  background: var(--text-color);
  transition: all 250ms ease-in-out;
}

._3waoy._3xiPD {
  border: none;
}

._3waoy._3xiPD:after {
  height: 100%;
  width: 100%;
}

._3vnt0:hover ._1O7aA._3GhlB {
  transform: scale(1.5) rotate(180deg) translate(0px);
}
._3vnt0:hover ._1O7aA._3utEv {
  transform: scale(1.5) translate(0px);
}

._1O7aA {
  z-index: 2;
  font-size: 48px;
  cursor: pointer;
  margin: 0px 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0px 16px;
  line-height: 48px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 28px);
  color: var(--text-color);
  transition: transform 0.3s;
  transform-origin: center center;
  -webkit-tap-highlight-color: transparent !important;
}

._1O7aA._3GhlB {
  padding-bottom: 8px;
  transform: scale(1.5) rotate(180deg) translate(150%);
}

._1O7aA._3utEv {
  right: 0;
  transform: scale(1.5) translate(150%);
}

._1O7aA._3GhlB._3sFhv {
  transform: scale(1.5) rotate(180deg) translate(0px) !important;
}

._1O7aA._3utEv._3sFhv {
  transform: scale(1.5) translate(0px) !important;
}

._1c_yW {
  width: 100%;
  height: 100%;
  display: flex;
  contain: strict;
  align-items: center;
  justify-content: center;
}

._2izpl {
  position: relative;
  overflow: hidden;
  width: inherit;
  z-index: 1;
}

._6iOKN {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  contain: strict;
}

._2U2Zh {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  min-width: 100%;
  min-height: 100%;
  will-change: transform;
  transition: opacity 0.3s cubic-bezier(.25,.8,.5,1);
}

._1RYKi {
  border: 0;
  height: 7px;
  border-radius: 10px;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset);
}

._1RYKi._1P-G7 {
  --bg-color: var(--light-bg);
  --box-shadow-inset: inset -3px -3px 5px var(--light-bg-light-shadow),
    inset 3px 3px 5px var(--light-bg-dark-shadow);
  --box-shadow-inset-dense: inset -1px -1px 3px var(--light-bg-light-shadow),
    inset 1px 1px 3px var(--light-bg-dark-shadow);
  --box-shadow: -2px -2px 5px var(--light-bg-light-shadow),
    2px 2px 5px var(--light-bg-dark-shadow);
  --box-shadow-dense: -1px -1px 3px var(--light-bg-light-shadow),
    1px 1px 3px var(--light-bg-dark-shadow);
}

._1RYKi._OnFhH {
  --bg-color: var(--dark-bg);
  --box-shadow-inset: inset -3px -3px 5px var(--dark-bg-light-shadow),
    inset 3px 3px 5px var(--dark-bg-dark-shadow);
  --box-shadow-inset-dense: inset -1px -1px 3px var(--dark-bg-light-shadow),
    inset 1px 1px 3px var(--dark-bg-dark-shadow);
  --box-shadow: -2px -2px 5px var(--dark-bg-light-shadow),
    2px 2px 5px var(--dark-bg-dark-shadow);
  --box-shadow-dense: -1px -1px 3px var(--dark-bg-light-shadow),
    1px 1px 3px var(--dark-bg-dark-shadow);
}

._1RYKi._2u50z {
  height: 5px;
  box-shadow: var(--box-shadow-inset-dense);
}

._1RYKi._3O_dH {
  height: 4px;
  box-shadow: var(--box-shadow);
}

._1RYKi._2u50z._3O_dH {
  height: 3px;
  box-shadow: var(--box-shadow-dense);
}

._19-yK {
  z-index: 10;
  width: auto;
  font-size: 14px;
  padding: 8px 16px;
  position: absolute;
  pointer-events: none;
  display: inline-block;
  transform-origin: center;
  transition: opacity 0.2s ease-in-out;
}

._3TFIz {
  position: fixed;
  top: 0;
  left: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
}

._3TFIz._1wCKz {
  --bg-color: var(--light-bg);
  --text-color: var(--g-text-color-light);
}

._3TFIz._3mO7A {
  --bg-color: var(--dark-bg);
  --text-color: var(--g-text-color-dark);
  --border-color: var(--dark-bg-light-shadow);
}

._3Reny {
  width: 100%;
  height: 100%;
  opacity: 0.96;
  background: var(--bg-color);
}

._2Vz13 {
  position: absolute;
}

._2VbRb {
  display: flex;
  font-size: 16px;
  position: relative;
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  color: var(--text-color);
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom)
    var(--padding-left);
  --padding-top: 12px;
  --padding-left: 16px;
  --padding-right: 16px;
  --padding-bottom: 12px;
}

._2VbRb._1WOK3 {
  --text-color: var(--g-text-color-light);
}

._2VbRb._3Rm6c {
  --text-color: var(--g-text-color-dark);
}

._2VbRb._1pX9H {
  --padding-top: 8px;
  --padding-left: 12px;
  --padding-right: 12px;
  --padding-bottom: 8px;
}

._2VbRb._3af_p {
  box-shadow: none !important;
  border: 1px solid var(--text-color);
}

._2VbRb._NpfzT {
  --padding-right: 12px;
}

._2VbRb._1pX9H._NpfzT {
  --padding-right: 8px;
}

._2VbRb._3i4gQ {
  --text-color: var(--info);
}

._2VbRb._fyVEY {
  --text-color: var(--error);
}

._2VbRb._-ELKr {
  --text-color: var(--warning);
}

._2VbRb._3FTCi {
  --text-color: var(--success);
}

._2VbRb._1I5lF {
  border-top: 6px solid var(--text-color);
  --padding-top: 6px;
}

._2VbRb._1I5lF._1pX9H {
  --padding-top: 2px;
}

._2VbRb._1sMNU {
  border-left: 6px solid var(--text-color);
  --padding-left: 10px;
}

._2VbRb._1sMNU._1pX9H {
  --padding-left: 6px;
}

._2VbRb._3HvGH {
  border-right: 6px solid var(--text-color);
  --padding-right: 10px;
}

._2VbRb._3HvGH._1pX9H {
  --padding-right: 6px;
}

._2VbRb._j4ouU {
  border-bottom: 6px solid var(--text-color);
  --padding-bottom: 6px;
}

._2VbRb._j4ouU._1pX9H {
  --padding-bottom: 2px;
}

._1hCqZ {
  padding-top: 4px;
  margin-right: 16px;
}

._3YisC {
  margin-left: 16px;
  color: inherit;
}

._1b2BB {
  font-size: 26px;
}

._1mmDS {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  --badge-bg-color: var(--primary);
  --badge-text-color: var(--white);
  --badge-border-color: var(--white);
}

._1roKQ {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

._18bV3 {
  display: flex;
  font-size: 12px;
  padding: 2px 6px;
  position: absolute;
  text-align: center;
  border-radius: 12px;
  white-space: nowrap;
  align-items: center;
  --badge-height: 20px;
  box-sizing: border-box;
  justify-content: center;
  --badge-offset-size: 3px;
  height: var(--badge-height);
  color: var(--badge-text-color);
  min-width: var(--badge-height);
  background: var(--badge-bg-color);
  transition: 0.3s ease-in-out;
}

._1roKQ ._18bV3 {
  position: relative;
  margin: 0 4px;
}

._2iR1P {
  padding: 0px;
  --badge-height: 8px;
  --badge-offset-size: 1.5px;
}

._1W1Kk {
  --badge-border-width: 2px;
  border: var(--badge-border-width) solid var(--badge-border-color);
}

._2iR1P._1W1Kk {
  --badge-border-width: 1.5px;
}

._1p8mO {
  border-radius: 0px;
  --badge-offset-size: 0px;
}

._10m0F {
  padding: 0px !important;
}

._A3ZNY {
  top: 0px;
}

._3uSSi {
  left: 0px;
}

._1on6L {
  right: 0px;
}

._2WUga {
  bottom: 0px;
}

._1o0kR {
  transform: translate(
    calc(100% - var(--badge-offset-size)),
    calc(-100% + var(--badge-offset-size))
  );
}

._NnFho {
  transform: translate(
    calc(-100% + var(--badge-offset-size)),
    calc(-100% + var(--badge-offset-size))
  );
}

._gX7rU {
  transform: translate(
    calc(100% - var(--badge-offset-size)),
    calc(100% - var(--badge-offset-size))
  );
}

._24Ik9 {
  transform: translate(
    calc(-100% + var(--badge-offset-size)),
    calc(100% - var(--badge-offset-size))
  );
}

._2Kj8C {
  --badge-offset-size: 12px;
}

._2iR1P._2Kj8C {
  --badge-offset-size: 9px;
}

._1QcJs {
  overflow-x: auto;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  background-color: var(--bg-color);
  -webkit-overflow-scrolling: touch;
}

._1QcJs._3LJtX {
  --bg-color: var(--light-bg);
  --text-color: var(--g-text-color-light);
  --border-color: var(--light-bg-dark-shadow);
  --box-shadow: 3px 3px 6px var(--light-bg-dark-shadow),
    -3px -3px 6px var(--light-bg-light-shadow);
  --box-shadow-2: 6px 6px 12px var(--light-bg-dark-shadow),
    -6px -6px 12px var(--light-bg-light-shadow);
  --box-shadow-3: 9px 9px 18px var(--light-bg-dark-shadow),
    -9px -9px 18px var(--light-bg-light-shadow);
  --box-shadow-4: 12px 12px 24px var(--light-bg-dark-shadow),
    -12px -12px 24px var(--light-bg-light-shadow);
  --box-shadow-5: 15px 15px 30px var(--light-bg-dark-shadow),
    -15px -15px 30px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--light-bg-light-shadow),
    inset 2px 2px 5px var(--light-bg-dark-shadow);
}

._1QcJs._piHct {
  --bg-color: var(--dark-bg);
  --text-color: var(--g-text-color-dark);
  --border-color: var(--dark-bg-light-shadow);
  --box-shadow: 3px 3px 6px var(--dark-bg-dark-shadow),
    -3px -3px 6px var(--dark-bg-light-shadow);
  --box-shadow-2: 6px 6px 12px var(--dark-bg-dark-shadow),
    -6px -6px 12px var(--dark-bg-light-shadow);
  --box-shadow-3: 9px 9px 18px var(--dark-bg-dark-shadow),
    -9px -9px 18px var(--dark-bg-light-shadow);
  --box-shadow-4: 12px 12px 24px var(--dark-bg-dark-shadow),
    -12px -12px 24px var(--dark-bg-light-shadow);
  --box-shadow-5: 15px 15px 30px var(--dark-bg-dark-shadow),
    -15px -15px 30px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--dark-bg-light-shadow),
    inset 2px 2px 5px var(--dark-bg-dark-shadow);
}

._1QcJs._XJSJ9 {
  box-shadow: none !important;
}

._1QcJs._13Kbp {
  box-shadow: var(--box-shadow-inset);
}

._1QcJs._17I3C {
  box-shadow: none !important;
  border: 1px solid var(--border-color);
}

._1gluf {
  border: 0;
  min-width: 100%;
  white-space: nowrap;
  table-layout: fixed;
  border-collapse: collapse;
}

._3DF_E {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  min-height: 56px;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

._3FEEB {
  border-bottom: 1px solid var(--border-color);
}

._1dDpP {
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
}

._1dDpP:last-of-type {
  border-bottom: none !important;
}

._1g9-Z,
._2Tfgb {
  padding: 16px;
}

._3Z8V1 ._1g9-Z,
._3Z8V1 ._2Tfgb {
  padding: 8px 16px !important;
}

._2Tfgb {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

._2gnwO {
  text-align: inherit;
}

._3QUGl {
  text-align: center;
}

._y_Im0 {
  text-align: left;
}

._2prGd {
  text-align: right;
}

._SFJMA {
  text-align: justify;
}

._37Trm {
  vertical-align: middle;
}

._1eCBM {
  vertical-align: bottom;
}

._2rq88 {
  vertical-align: top;
}

._23EQ4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

._3U_xV {
    flex-direction: column;
}

._2xbD- {
  display: flex;
  margin: 4px 12px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-items: flex-start;
  --focused-color: var(--primary);
}

._fWLwS {
  cursor: text;
  position: relative;
  box-sizing: border-box;
}

._U6nBC {
  outline: none;
  border: none;
  height: 40px;
  font-size: 16px;
  min-height: 40px;
  padding: 0px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--text-color);
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset);
  transition: box-shadow 0.2s ease-in-out;
}

._U6nBC._31Os5 {
  resize: vertical;
  padding: 11px 16px 9px 16px;
}

._fWLwS._2aC8_ {
  opacity: 0.6;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

._2P-4r ._U6nBC {
  height: 32px;
  min-height: 32px;
  padding: 0px 16px;
}

._2P-4r ._U6nBC._31Os5 {
  height: 32px;
  min-height: 32px;
  padding: 7px 16px 5px 16px;
}

._U6nBC._2nHt_ {
  --bg-color: var(--light-bg);
  --selector-bg: var(--text-secondary);
  --text-color: var(--g-text-color-light);
  --border-color: var(--light-bg-light-shadow);
  --text-secondary: var(--g-text-color-secondary-light);
  --disabled-color: var(--g-text-color-disabled-light);
  --box-shadow-inset: inset 2px 2px 5px var(--light-bg-dark-shadow),
    inset -2px -2px 6px var(--light-bg-light-shadow);
  --box-shadow-inset-thin: inset 1px 1px 2px var(--light-bg-dark-shadow),
    inset -1px -1px 2px var(--light-bg-light-shadow);
}

._U6nBC._1a45g {
  --bg-color: var(--dark-bg);
  --selector-bg: var(--text-secondary);
  --text-color: var(--g-text-color-dark);
  --border-color: var(--dark-bg-light-shadow);
  --text-secondary: var(--g-text-color-secondary-dark);
  --disabled-color: var(--g-text-color-disabled-dark);
  --box-shadow-inset: inset 2px 2px 5px var(--dark-bg-dark-shadow),
    inset -2px -2px 6px var(--dark-bg-light-shadow);
  --box-shadow-inset-thin: inset 1px 1px 2px var(--dark-bg-dark-shadow),
    inset -1px -1px 2px var(--dark-bg-light-shadow);
}

._U6nBC._2EcQN::-webkit-input-placeholder {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

._U6nBC._2EcQN::-moz-placeholder {
  -moz-user-select: none;
       user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

._U6nBC._2EcQN:-ms-input-placeholder {
  -ms-user-select: none;
      user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

._U6nBC._2EcQN::-ms-input-placeholder {
  -ms-user-select: none;
      user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

._U6nBC._2EcQN::placeholder,
._U6nBC._2EcQN {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

._U6nBC._2EcQN {
  box-shadow: var(--box-shadow-inset);
}

._U6nBC._2sOuT {
  border: 1px solid var(--border-color);
}

._U6nBC._2zBZA,
._fWLwS._17ek- {
  border-radius: 24px;
}

/* hover and focus */
._U6nBC:hover,
._U6nBC:focus {
  box-shadow: var(--box-shadow-inset-thin);
}

._U6nBC._26ECL:hover,
._U6nBC._26ECL:focus {
  --selector-bg: var(--text-color);
}

._U6nBC._26ECL,
._U6nBC._26ECL:hover {
  box-shadow: none !important;
  border: 1px solid var(--selector-bg);
}

._U6nBC._26ECL:focus {
  --selector-bg: var(--primary);
  transition: none;
  border: 1.5px solid var(--selector-bg);
}

._U6nBC._2EcQN:hover,
._U6nBC._2EcQN:focus {
  box-shadow: var(--box-shadow-inset);
}
/* end of hover and focus */

._Mk5N_ {
  z-index: 2;
  top: 11px;
  left: 17px;
  right: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  cursor: text !important;
}

._2P-4r ._Mk5N_ {
  top: 6px;
}

._3t8tT {
  width: 100%;
  display: flex;
  margin-top: 4px;
  align-items: center;
  justify-content: space-between;
}

._1xCHA,
._2Lw0g {
  height: 12px;
  padding-left: 16px;
  transition: none !important;
  transition: all 0.3s ease-in-out;
}

._1xCHA {
  color: var(--error);
}

._2MZzb {
  padding-left: 8px;
  padding-right: 16px;
}

._3VWSl {
  top: -2px;
  left: 50%;
  position: absolute;
  width: calc(100% - 16px);
  transform: translateX(-50%);
}

._17ek- ._3VWSl {
  width: calc(100% - 36px);
}

._2mihT,
._1dTNN {
  padding-top: 8px;
}

._1dTNN {
  padding-left: 16px;
}
._2mihT {
  padding-right: 16px;
}

._2g7Fh {
  position: relative;
  transition: all 0.2s ease-in-out;
}

._2g7Fh._37QLn {
  --bg-color: var(--light-bg);
  --selector-bg: var(--text-color);
  --text-color: var(--g-text-color-light);
  --disabled-bg: var(--g-text-color-disabled-light);
  --box-shadow: 2px 2px 4px var(--light-bg-dark-shadow),
    -2px -2px 4px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -1px -1px 2px var(--light-bg-light-shadow),
    inset 1px 1px 2px var(--light-bg-dark-shadow);
}

._2g7Fh._nacDr {
  --bg-color: var(--dark-bg);
  --selector-bg: var(--text-color);
  --text-color: var(--g-text-color-dark);
  --disabled-bg: var(--g-text-color-disabled-dark);
  --box-shadow: 2px 2px 4px var(--dark-bg-dark-shadow),
    -2px -2px 4px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -1px -1px 2px var(--dark-bg-light-shadow),
    inset 1px 1px 2px var(--dark-bg-dark-shadow);
}

._2g7Fh:before {
  content: '';
  z-index: 0;
  top: -3px;
  left: -3px;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  position: absolute;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

._2g7Fh:after {
  content: '';
  z-index: 2;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  display: block;
  cursor: pointer;
  position: absolute;
  border-radius: 6px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

._2g7Fh:before {
  background-color: var(--bg-color);
  box-shadow: var(--box-shadow-inset);
}

._2g7Fh:after {
  background-color: var(--text-color);
}

._2g7Fh:checked:before {
  box-shadow: var(--box-shadow);
}

._2g7Fh:checked:after {
  background-color: var(--selector-bg);
  box-shadow: none;
}

._2g7Fh._24Y84:after,
._2g7Fh:checked._24Y84:after {
  background-color: var(--disabled-bg);
  box-shadow: none;
}

._2g7Fh:hover:after,
._2g7Fh:focus:after {
  background-color: var(--selector-bg);
}

._2g7Fh._24Y84,
._30uF4._1EeO5 {
  pointer-events: none;
}

._30uF4 {
  padding-left: 12px;
}

._2DIew {
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-appearance: none;
  box-shadow: var(--box-shadow);
  background-color: var(--bg-color);
  transition: all 200ms ease-in-out;
}

._2DIew._1u_cB {
  --bg-color: var(--light-bg);
  --selector-bg: var(--text-color);
  --text-color: var(--g-text-color-light);
  --text-disabled: var(--g-text-color-disabled-light);
  --box-shadow: 2px 2px 4px var(--light-bg-dark-shadow),
    -2px -2px 4px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 3px var(--light-bg-light-shadow),
    inset 2px 2px 3px var(--light-bg-dark-shadow);
}

._2DIew._a77__ {
  --bg-color: var(--dark-bg);
  --selector-bg: var(--text-color);
  --text-color: var(--g-text-color-dark);
  --text-disabled: var(--g-text-color-disabled-dark);
  --box-shadow: 2px 2px 4px var(--dark-bg-dark-shadow),
    -2px -2px 4px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 3px var(--dark-bg-light-shadow),
    inset 2px 2px 3px var(--dark-bg-dark-shadow);
}

._2DIew:before,
._2DIew:after {
  content: "";
  top: 9px;
  left: 4.5px;
  position: absolute;
  pointer-events: none;
  transform-origin: 0% 100%;
}

._2DIew:before {
  z-index: 2;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

._2DIew:after {
  width: 3px;
  height: 8px;
  border-right: 2px solid var(--text-color);
  border-bottom: 2px solid var(--text-color);
  transform: translate3d(0, -8px, 0) rotate(45deg);
}

._2DIew:checked {
  box-shadow: var(--box-shadow-inset);
}

._2DIew:hover:before,
._2DIew:checked:not(:hover):before {
  -webkit-animation: _JRLap 200ms cubic-bezier(.4, .0, .23, 1) forwards;
          animation: _JRLap 200ms cubic-bezier(.4, .0, .23, 1) forwards;
}

._2DIew:not(:hover):before {
  -webkit-animation: _BF55r 150ms cubic-bezier(.4, .0, .23, 1) forwards;
          animation: _BF55r 150ms cubic-bezier(.4, .0, .23, 1) forwards;
}

._2DIew._2qmYE,
._aIUGu._3QbeY {
  pointer-events: none !important;
  --selector-bg: var(--text-disabled) !important;
  --text-color: var(--text-disabled) !important;
}

._2DIew._2qmYE:checked:before,
._2DIew._2qmYE:checked:after {
  left: 4.5px !important;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

._aIUGu {
  font-size: 16px;
  margin-top: -2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 8px;
  color: var(--text-color);
}


@-webkit-keyframes _JRLap {
  0% {
    width: 0;
    height: 0;
    border-color: var(--selector-bg);
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  70% {
    width: 3px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  100% {
    width: 3px;
    height: 8px;
    border-color: var(--selector-bg);
    transform: translate3d(0, -8px, 0) rotate(45deg);
  }
}


@keyframes _JRLap {
  0% {
    width: 0;
    height: 0;
    border-color: var(--selector-bg);
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  70% {
    width: 3px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  100% {
    width: 3px;
    height: 8px;
    border-color: var(--selector-bg);
    transform: translate3d(0, -8px, 0) rotate(45deg);
  }
}

@-webkit-keyframes _BF55r {
  0% {
    width: 3px;
    height: 8px;
    border-color: var(--selector-bg);
    transform: translate3d(0, -8px, 0) rotate(45deg);
  }

  60% {
    width: 3px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  90% {
    width: 0;
    height: 0;
    border-color: var(--selector-bg);
    transform: translate3d(0px, 0, 0) rotate(45deg);
  }

  100% {
    border-color: transparent;
    transform: translate3d(0px, 0, 0) rotate(45deg);
  }
}

@keyframes _BF55r {
  0% {
    width: 3px;
    height: 8px;
    border-color: var(--selector-bg);
    transform: translate3d(0, -8px, 0) rotate(45deg);
  }

  60% {
    width: 3px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  90% {
    width: 0;
    height: 0;
    border-color: var(--selector-bg);
    transform: translate3d(0px, 0, 0) rotate(45deg);
  }

  100% {
    border-color: transparent;
    transform: translate3d(0px, 0, 0) rotate(45deg);
  }
}

._AQLzt {
  width: 26px;
  height: 26px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
}

._AQLzt._1zQ0s {
  --bg-color: var(--light-bg);
  --selector-bg: var(--text-color);
  --text-color: var(--g-text-color-light);
  --text-disabled: var(--g-text-color-disabled-light);
  --box-shadow-small: 1px 1px 2px var(--light-bg-dark-shadow),
    -1px -1px 2px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 3px var(--light-bg-light-shadow),
    inset 2px 2px 3px var(--light-bg-dark-shadow);
}

._AQLzt._BQ4h8 {
  --bg-color: var(--dark-bg);
  --selector-bg: var(--text-color);
  --text-color: var(--g-text-color-dark);
  --text-disabled: var(--g-text-color-disabled-dark);
  --box-shadow-small: 1px 1px 2px var(--dark-bg-dark-shadow),
    -1px -1px 2px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 3px var(--dark-bg-light-shadow),
    inset 2px 2px 3px var(--dark-bg-dark-shadow);
}

._AQLzt:hover:before {
  background-color: var(--selector-bg);
}

._AQLzt:before,
._AQLzt:after {
  content: "";
  overflow: hidden;
  position: absolute;
  border-radius: 32px;
  background-color: var(--bg-color);
}

._AQLzt:before {
  z-index: 2;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  transform: translate3d(0%, 0, 0);
  box-shadow: var(--box-shadow-small);
  transition: all 0.2s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

._AQLzt:after {
  z-index: 1;
  width: 48px;
  height: 28px;
  isolation: isolate;
  box-shadow: var(--box-shadow-inset);
}

._AQLzt:checked:before {
  transform: translate3d(100%, 0, 0);
  background-color: var(--selector-bg);
}

._AQLzt._C4xuu:before {
  background-color: var(--text-disabled) !important;
}

._AQLzt._C4xuu {
  opacity: 0.75;
}

._2hoJO {
  font-size: 16px;
  margin-left: 28px;
  margin-top: -2px;
  color: var(--text-color);
}
